import Vue from 'vue'
import VueNativeSock from 'vue-native-websocket'
import {WS_URL} from '@/config/constants'
import store from '@/store'

const options = {
    store: store,
    format: 'json',
    connectManually: true,
}
Vue.use(VueNativeSock, WS_URL, options)
