export default {
    tenantId: '',
    authToken: '',
    isFinished: false,
    user: {},
    socket: {
        isConnected: false,
        message: '',
        reconnectError: false
    },
    alert: {
        isVisible: false,
        alertData: {}
    },
    modal: {
        isVisible: false,
        componentName: 'ModalDefault',
        componentData: {}
    },
    testUser: {
        id: 1,
        userId: 1,
        firstName: 'Test',
        lastName: 'Ted',
        name: 'Test Ted',
        password: 'Random12',
        role: 'super-admin',
        email: 'support@codesource.com.au'
    },
    toSendQueue: [],
    messages: [],
    customerAppOrigin: '',
    customerAppKey: '',
    companyName: 'Jeeves',
    welcomeText: '',
    fontSize: 0,
    _isOperatorOnline: false
}
