import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import VueI18n from 'vue-i18n'
import VueSanitize from 'vue-sanitize'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

// import VueNativeSock from 'vue-native-websocket'
import '@/plugins/vue-native-websocket'
import '@/plugins/maz-ui'

import VueAxios from 'vue-axios'
import axios from '@/utils/axios'
import device from 'vue-device-detector'
import UUID from 'vue-uuid'
import { AuthPlugin } from './auth'


import 'vuetify/dist/vuetify.css'
import '@/scss/main.scss'
import VueAnchorme from '@/plugins/anchorme'

Vue.use(VueAxios, axios)
Vue.use(device)
Vue.use(AuthPlugin)
Vue.use(UUID)
Vue.use(VueI18n)
Vue.use(VueSanitize)
Vue.use(VueAnchorme)
const options = {}
// can configure global options here
Vue.use(VueTelInput, options)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
