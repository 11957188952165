import colors from './colors'


export default {
    primary: colors.blue.base,
    secondary: colors.white,
    // All keys will generate theme styles,
    // Here we add a custom `tertiary` color
    warning: colors.orange.base,
    accent: colors.blue.lighten1,
    error: colors.red.base,
    success: colors.green.base,
    info: colors.grey.base,
    infoLight: colors.grey.light,
    infoLight2: colors.grey.light2,
}