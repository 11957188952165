class MessageModel {
    constructor(id, author, name, data, timestamp) {
        this.id = id
        this.author = author
        this.name = name
        this.data = data
        this.timestamp = timestamp
    }
}

export {
    MessageModel
}
