import axios from 'axios'
import {API_URL, API_KEY_HEADER_NAME} from '@/config/constants'
import store from '@/store/index'

const headers = {}

const instance = axios.create({
    baseURL: API_URL,
    headers
})

const axiosRequestInterceptor = async (config) => {
    config.headers[API_KEY_HEADER_NAME] = store.getters.customerAppKey

    return config
}

instance.interceptors.request.use(axiosRequestInterceptor, (e) => Promise.reject(e))

export default instance
