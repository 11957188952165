import Vue from 'vue'
import {
    ALERT_IS_VISIBLE,
    ALERT_DATA,
    MODAL_IS_VISIBLE,
    MODAL_DATA,
    MODAL_COMPONENT_NAME,
    SET_TENANT_ID,
    ADD_MESSAGE,
    ADD_MESSAGES,
    SET_MESSAGES,
    SET_CUSTOMER_APP_ORIGIN,
    SET_AUTH_TOKEN,
    SET_USER,
    SET_FINISHED,
    PUSH_MESSAGE_TO_QUEUE,
    POP_MESSAGE_FROM_QUEUE,
    CLEAR_MESSAGE_QUEUE, SET_IS_AN_OPERATOR_ONLINE,
    SET_CUSTOMER_APP_KEY, SET_COMPANY_NAME, SET_WELCOME_TEXT, SET_IS_EXTERNAL, SET_FONT_SIZE
} from '@/store/base/mutationTypes'

export default {
    [ALERT_IS_VISIBLE](state, isVisible) {
        state.alert.isVisible = isVisible
    },
    [ALERT_DATA](state, alertData) {
        state.alert.alertData = alertData
    },
    [MODAL_IS_VISIBLE](state, isVisible) {
        state.modal.isVisible = isVisible
    },
    [MODAL_DATA](state, componentData) {
        state.modal.componentData = componentData
    },
    [MODAL_COMPONENT_NAME](state, componentName) {
        state.modal.componentName = componentName
    },
    [SET_AUTH_TOKEN](state, authToken) {
        state.authToken = authToken
    },
    [SET_TENANT_ID](state, tenantId) {
        state.tenantId = tenantId
    },
    [SET_USER](state, user) {
        state.user = user
    },
    [SET_FINISHED](state, isFinished) {
        state.isFinished = isFinished
    },
    [ADD_MESSAGE](state, message) {
        state.messages.push(message)
    },
    [ADD_MESSAGES](state, messages) {
        state.messages = state.messages.concat(messages)
    },
    [PUSH_MESSAGE_TO_QUEUE](state, message) {
        state.toSendQueue = [message, ...state.toSendQueue]
    },
    [POP_MESSAGE_FROM_QUEUE](state) {
        state.toSendQueue.pop()
    },
    [CLEAR_MESSAGE_QUEUE](state) {
        state.toSendQueue = []
    },
    [SET_MESSAGES](state, messages) {
        state.messages = messages
    },
    [SET_CUSTOMER_APP_ORIGIN](state, origin) {
        state.customerAppOrigin = origin
    },
    [SET_CUSTOMER_APP_KEY](state, key) {
        state.customerAppKey = key
    },
    [SET_COMPANY_NAME](state, name) {
        state.companyName = name
    },
    [SET_IS_EXTERNAL](state, isExternal) {
        state.isExternal = isExternal
    },
    [SET_WELCOME_TEXT](state, welcomeText) {
        state.welcomeText = welcomeText
    },
    [SET_FONT_SIZE](state, fontSize) {
        state.fontSize = fontSize
    },
    [SET_IS_AN_OPERATOR_ONLINE](state, isOperatorOnline) {
        state._isOperatorOnline = isOperatorOnline
    },
    SOCKET_ONOPEN (state, event)  {
        Vue.prototype.$socket = event.currentTarget
        state.socket.isConnected = true
    },
    SOCKET_ONCLOSE (state, event)  {
        console.log('event from close', event)
        state.socket.isConnected = false
    },
    SOCKET_ONERROR (state, event)  {
        console.error(state, event)
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE (state, message)  {
        state.socket.message = message
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
        console.info(state, count)
    },
    SOCKET_RECONNECT_ERROR(state) {
        state.socket.reconnectError = true;
    },
}
