import Vue from 'vue'
import snakecaseKeys from 'snakecase-keys'

let instance

/** Returns the current instance of the SDK */
export const getInstance = () => instance

/** Creates an instance of the Auth0 SDK. If one has already been created, it returns that instance */
export const useAuth = () => {
    if (instance) return instance

    // The 'instance' is simply a Vue object
    instance = new Vue({
        data() {
            return {
                loading: true,
                isAuthenticated: false,
                user: {},
                error: null
            }
        },
        methods: {
            storeToken(token) {
                localStorage.setItem(process.env.VUE_APP_TOKEN_ACCESS, JSON.stringify(snakecaseKeys(token, {deep: true})))
            },
            redirectToLogin() {
                return this.$nextTick(() => this.$router.push({name: 'onboarding'}))
            },
            logout() {
                console.debug('logout')
                this.redirectToLogin()
            }
        }
    })

    return instance
}

// Create a simple Vue plugin to expose the wrapper object throughout the application
export const AuthPlugin = {
    install(Vue, options) {
        Vue.prototype.$auth = useAuth(options)
    }
}
