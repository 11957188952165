<template>
    <v-app>
        <v-main class="h-full" :class="{'full-sized-app': isExternal}">
            <div class="flex flex-col h-full">
                <GlobalHeader v-if="!loggedIn"/>
                <router-view/>
                <GlobalFooter v-if="!loggedIn"/>
            </div>
        </v-main>
        <component :is="modalActive" class="absolute"/>
        <component
            top
            class="mt-8"
            :is="alertActive"
            v-model="snackbar"
            centered
            :vertical="vertical"
            :color="alertColor"
            :timeout="timeout"
            max-width="300px"
            min-width="300px"
        >
            {{ alertText }}
            <template v-slot:action="{ attrs }">
                <v-btn color="white" text v-bind="attrs" @click="setAlertInactive">
                    Close
                </v-btn>
            </template>
        </component>
    </v-app>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import Modal from '@/components/shared/Modal'
import {VSnackbar} from 'vuetify/lib'

import GlobalHeader from '@/components/shared/GlobalHeader'
import GlobalFooter from '@/components/shared/GlobalFooter'
import {getStorage} from '@/utils/storage.helpers'
import CustomerAppHelper from '@/components/shared/mixins/customerAppHelper'

export default {
    name: 'App',
    components: {
        Modal,
        VSnackbar,
        GlobalHeader,
        GlobalFooter
    },
    mixins: [CustomerAppHelper],
    data() {
        return {
            vertical: true,
            timeout: 5000,
            // timeout: -1, // This is just used for testing
            snackbar: true,
            inView: false,
            reconnectStates: [WebSocket.CLOSED],
            openStates: [WebSocket.OPEN, WebSocket.CONNECTING],
            isExternal: true,
        }
    },
    async mounted() {
        const companyName = await this.loadCompanyName()
        if (this.$route.path === '/' && !(await this.loadCustomerAppKey()) && !companyName) return
        this.isExternal = await this.loadExternal()
        const authToken = await this.loadSessionToken()

        this.setupChannelWithCustomerApp()

        // on mount we check if we have an authToken since we are just loading the component
        const authTokenLocalStorage = this.getAuthTokenLocalStorage()
        // either user has openned a new tab or come back to the chat so we want to reset it
        if (authTokenLocalStorage && !this.authToken) this.setToken(authTokenLocalStorage)
        else if (authToken) this.setToken(authToken)
        else await this.removeWebChat()
        if (authToken) await this.$router.push({name: 'chat'})
        this.detectFocusOut()
    },
    methods: {
        ...mapActions([
            'setToken',
            'removeWebChat'
        ]),
        getAuthTokenLocalStorage() {
            const storage = getStorage()
            return storage?.authToken || ''
        },
        getIsFinished() {
            const storage = getStorage()
            return storage?.finished || false
        },
        setAlertInactive() {
            this.$store.commit('ALERT_IS_VISIBLE', false)
            this.$store.commit('ALERT_DATA', {})
        },
        checkSocket() {
            const localStorageAuth = this.getAuthTokenLocalStorage()
            const isFinished = this.getIsFinished()
            // this case is for when we are logged out of one tab and come back to a new one, but haven't signed in yet
            if (localStorageAuth && !this.authToken) this.setToken(localStorageAuth)

            const haveToken = localStorageAuth != null && localStorageAuth !== '' && localStorageAuth === this.authToken
            // we are connected, so do nothing - go to next window
            if (this.openStates.indexOf(this.$socket?.readyState) > -1 && haveToken) {
                if (this.$route.name !== 'chat') this.$nextTick(() => this.$router.push({name: 'chat'}))
                return
            }
            // if there is an authToken but there still is a socket, disconnect
            if (this.$socket) {
                if (!haveToken && this.$socket.readyState === WebSocket.OPEN) {
                    this.$disconnect()
                }
                if (haveToken && this.reconnectStates.indexOf(this.$socket.readyState) > -1) {
                    // we have a socket and an authToken, let's try connecting
                    try {
                        this.$connect()
                    } catch (e) {
                        console.log('exception found', e)
                        // if something went wrong, let's disconnect/remove connections and try again
                        //TODO: show an error message or retry connection
                        this.removeWebChat()
                        this.$router.push({name: 'onboarding'})
                    }
                }
            }

            // if we have an authToken and no socket, let's try connecting
            if (!this.$socket && haveToken) this.$connect()

            this.$nextTick(() => {
                // if we don't have a connection by now then we clear the token and go to the onboarding page
                if (!this.socket.isConnected && (!this.$socket || [WebSocket.CLOSED, WebSocket.CLOSING].indexOf(this.$socket?.readyState) > -1)) {
                    this.setToken('')
                    // now we redirect if we aren't already on the onboarding component
                    // if we are finished and not on that page yet, we go there
                    if (this.$route.name !== 'onboarding' && !isFinished) this.$router.push({name: 'onboarding'})
                    else if (isFinished && this.$route.name !== 'finished-chat') this.$router.push({name: 'finished-chat'})
                }
            })
        },
        detectFocusOut() {
            let inView = false

            const onWindowFocusChange = (e) => {
                if ({ focus: 1, pageshow: 1 }[e.type]) {
                    if (inView) return
                    this.tabFocus = true
                    inView = true
                    this.checkSocket()
                } else if (inView) {
                    this.tabFocus = !this.tabFocus
                    inView = false
                }
            }

            window.addEventListener('focus', onWindowFocusChange)
            window.addEventListener('blur', onWindowFocusChange)
            window.addEventListener('pageshow', onWindowFocusChange)
            window.addEventListener('pagehide', onWindowFocusChange)
        }
    },
    computed: {
        ...mapGetters(['customerAppKey', 'authToken']),
        ...mapState({
            modal: state => state.app.modal,
            alert: state => state.app.alert,
            socket: state => state.app.socket
        }),
        modalActive() {
            return this.modal.isVisible ? 'Modal' : ''
        },
        alertActive() {
            return this.alert.isVisible ? 'v-snackbar' : ''
        },
        alertText() {
            return this.alert && this.alert.alertData ? this.alert.alertData.text : ''
        },
        alertColor() {
            return this.alert && this.alert.alertData
                ? this.alert.alertData.color
                : ''
        },
        loggedIn() {
            return true
        },
    },
    watch: {
        // we check if the socket has closed
        'socket.isConnected'() {
            console.log('we have connected to the socket?', this.socket.isConnected)
            // so if we have disconnected from the socket, we should go back to the first page
            this.checkSocket()
        },
        snackbar() {
            if (this.snackbar === false) this.setAlertInactive()
        },
        alert: {
            deep: true,
            handler() {
                if (this.alert && this.alert.alertData && this.alert.alertData.text)
                    this.snackbar = true
            }
        }
    },
    destroyed() {
        // if the page refreshes or something else happens, we terminate the chat
        this.$disconnect()
        this.removeWebChat()
        this.destroyChannelWithCustomerApp()
    }
}
</script>
