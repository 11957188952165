<template>
  <footer></footer>
</template>

<script>
export default {
  name: 'GlobalFooter',
  computed: {
    getCurrentYear() {
      const date = new Date()
      return date.getFullYear()
    }
  }
}
</script>
