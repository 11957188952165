const API_URL = process.env.VUE_APP_CHAT_API_URL
const API_KEY_HEADER_NAME = process.env.VUE_APP_CHAT_API_KEY_HEADER_NAME
const WS_URL = process.env.VUE_APP_CHAT_WS_URL
const STORAGE_KEY = process.env.VUE_APP_STORAGE_KEY
const CHAT_TITLE = process.env.VUE_APP_CHAT_TITLE

const STAGES = {
    NONE: '',
    MOBILE_NUMBER_INPUT: 'MOBILE_NUMBER_INPUT',
    PHONE_VERIFICATION: 'PHONE_VERIFICATION',
    REGISTRATION: 'REGISTRATION'
}

const AUTHORS = {
    ME: 'me',
    SUPPORT: 'support'
}

const AUTHOR_NAMES = {
    SUPPORT: 'Support Team'
}

const API_STATUSES = {
    EXISTS: 'CUSTOMER ALREADY EXISTS',
    FOUND: 'CUSTOMER FOUND',
    LOGGED_IN: 'CUSTOMER LOGGED IN',
    ALREADY_LOGGED_IN: 'CUSTOMER ALREADY LOGGED IN',
    MESSAGE_SENT: 'MESSAGE SENT',
}

const MESSAGE_STATUSES = {
    SUBMITTED: 'MESSAGE SUBMITTED',
    INVALID_SESSION: 'SESSION IS NOT VALID',
    CONNECTED: ''
}

const WELCOME_TEXT_TO_CHANGE = '_COMPANY_NAME_'
const WELCOME_MESSAGE_TEXT = 'Welcome to _COMPANY_NAME_! How can we help today?'

const CUSTOMER_APP_SIGNALS = {
    HANDSHAKE: 'handshake',
    CLOSE: 'close',
    FINISH: 'finish'
}

const ON_BOARDING_MESSAGES = {
    MOBILE_NOT_FOUND: 'As this is the first time you have engaged with us, can you please register your mobile number as well as your first and last name so as we can be of assistance? Thank you.'
}

const FONT_SIZES = [
    1, 1.1, 1.2, 1.3, 1.4
]

export {
    API_URL,
    API_KEY_HEADER_NAME,
    WS_URL,
    STAGES,
    AUTHORS,
    AUTHOR_NAMES,
    API_STATUSES,
    STORAGE_KEY,
    MESSAGE_STATUSES,
    WELCOME_MESSAGE_TEXT,
    CUSTOMER_APP_SIGNALS,
    CHAT_TITLE,
    WELCOME_TEXT_TO_CHANGE,
    ON_BOARDING_MESSAGES,
    FONT_SIZES
}
