<template>
  <div
    class="a-modal fixed inset-0 flex flex-col z-50 items-center justify-center active"
    tabindex="0"
    v-if="$store.state.app.modal.isVisible"
  >
    <div
      class="a-modal__content top-0 absolute bg-white-pure"
      :class="{
        'w-full h-full': $device.mobile,
        'max-h-screen lg:top-auto px-5 py-4 rounded-lg l-box-shadow': !$device.mobile
      }"
    >
      <component :is="componentName" class="" />
    </div>

    <div
      class="a-modal__screen-bg w-full h-full inset-0"
      @click="bgClickHandler"
      @keyup.escape="bgClickHandler"
      @keyup.enter="bgClickHandler"
    ></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Modal',
  methods: {
    bgClickHandler() {
      // console.log('bg clicked', e)
      if (this.data && !this.data.keepOpen)
        this.$store.commit('MODAL_IS_VISIBLE', false)
      // console.log('store app', this.$store.state.app)
    }
  },
  computed: {
    ...mapState({
      isVisible: (state) => state.app.modal.isVisible,
      componentName: (state) => state.app.modal.componentName,
      data: (state) => state.app.modal.componentData
    })
  }
}
</script>
