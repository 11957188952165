// import {STORAGE_KEY} from '@/config/constants'

export default {
    messages: (state) => state.messages,
    messageQueue: (state) => state.toSendQueue,
    customerAppOrigin: (state) => state.customerAppOrigin,
    customerAppKey: (state) => state.customerAppKey,
    isFinished: (state) => state.isFinished,
    authToken: (state) => state.authToken,
    welcomeText: (state) => state.welcomeText || '',
    fontSize: (state) => state.fontSize,
    isOperatorOnline: (state) => state._isOperatorOnline,
}
