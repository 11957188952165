import {CUSTOMER_APP_SIGNALS} from '@/config/constants'
import {mapActions} from 'vuex'

const CustomerAppHelper = {
    methods: {
        ...mapActions([
            'setCustomerAppKey',
            'setCustomerAppOrigin',
            'setCompanyName',
            'setIsExternal',
            'setToken'
        ]),
        async loadCustomerAppKey() {
            const customerAppKey = this.$route.query._
            if (!customerAppKey) {
                this.$router.push('/InvalidAppKey')
                return false
            }

            await this.setCustomerAppKey(customerAppKey)
            return customerAppKey
        },
        async loadCompanyName() {
            const companyName = this.$route.query.c
            if (!companyName) {
                await this.$router.push('/InvalidCompanyName')
                return false
            }

            await this.setCompanyName(companyName)
            return companyName
        },
        async loadExternal() {
            const isExternal = this.$route.query.i

            await this.setIsExternal(isExternal)
            return isExternal
        },
        async loadSessionToken() {
            const sessionToken = this.$route.query.s

            await this.setToken(sessionToken)
            return sessionToken
        },
        setupChannelWithCustomerApp() {
            window.addEventListener('message', this.onCustomerAppSignalReceived.bind(this), false)
        },
        onCustomerAppSignalReceived(event) {
            // console.debug('got a signal from the customer app: ', event)
            let {signal, appKey} = event.data

            switch (signal) {
                case CUSTOMER_APP_SIGNALS.HANDSHAKE:
                    if (appKey === this.customerAppKey) {
                        console.debug('handshake success with the customer app', event.origin)
                        this.setCustomerAppOrigin(event.origin)
                    }
                    break
                default:
            }
        },
        sendSignalToCustomerApp(signalObj) {
            if (!this.customerAppOrigin) return
            console.debug(
                'sending a signal to the customer app: ',
                signalObj,
                this.customerAppOrigin
            )

            window.parent && window.parent.postMessage(signalObj, this.customerAppOrigin)
        },
        destroyChannelWithCustomerApp() {
            window.removeEventListener('message', this.onCustomerAppSignalReceived)
        }
    }
}

export default CustomerAppHelper
