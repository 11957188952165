import {STORAGE_KEY} from '@/config/constants'
import store from '@/store/index'

const getStorageKey = () => {
    return `${STORAGE_KEY}-${store.getters.customerAppKey}`
}

const getStorage = () => {
    const item = localStorage.getItem(getStorageKey())
    let storage = {}
    if (item && typeof item === 'string') storage = JSON.parse(item)
    else if (item) storage = item
    return storage
}

const setStorage = (item) => {
    localStorage.setItem(getStorageKey(), JSON.stringify(item))
}

const removeStorage = () => {
    localStorage.removeItem(getStorageKey())
}

export {
    getStorage,
    setStorage,
    removeStorage
}
