export const ALERT_IS_VISIBLE = 'ALERT_IS_VISIBLE'
export const ALERT_DATA = 'ALERT_DATA'

// Modal state mutations
export const MODAL_IS_VISIBLE = 'MODAL_IS_VISIBLE'
export const MODAL_DATA = 'MODAL_DATA'
export const MODAL_COMPONENT_NAME = 'MODAL_COMPONENT_NAME'

export const SET_TENANT_ID = 'SET_TENANT_ID'
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN'
export const SET_USER = 'SET_USER'
export const SET_FINISHED = 'SET_FINISHED'

export const ADD_MESSAGE = 'ADD_MESSAGE'
export const ADD_MESSAGES = 'ADD_MESSAGES'
export const SET_MESSAGES = 'SET_MESSAGES'
export const PUSH_MESSAGE_TO_QUEUE = 'PUSH_MESSAGE_TO_QUEUE'
export const POP_MESSAGE_FROM_QUEUE = 'POP_MESSAGE_FROM_QUEUE'
export const CLEAR_MESSAGE_QUEUE = 'CLEAR_MESSAGE_QUEUE'

export const SET_CUSTOMER_APP_ORIGIN = 'SET_CUSTOMER_APP_ORIGIN'
export const SET_CUSTOMER_APP_KEY = 'SET_CUSTOMER_APP_KEY'
export const SET_COMPANY_NAME = 'SET_COMPANY_NAME'
export const SET_IS_EXTERNAL = 'SET_IS_EXTERNAL'
export const SET_WELCOME_TEXT = 'SET_WELCOME_TEXT'
export const SET_FONT_SIZE = 'SET_FONT_SIZE'
export const SET_IS_AN_OPERATOR_ONLINE = 'SET_IS_AN_OPERATOR_ONLINE'
