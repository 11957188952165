import Vue from 'vue'
import VueRouter from 'vue-router'
// import {authGuard} from '@/auth'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
    // {
    //     path: '/',
    //     redirect: '/Onboarding'
    // },
    {
        path: '/InvalidAppKey',
        name: 'invalid-app-key',
        component: () => import(/* webpackChunkName: "invalid-app-key" */ '@/views/InvalidAppKey.vue'),
        // beforeEnter: authGuard
    },
    {
        path: '/InvalidCompanyName',
        name: 'invalid-company-name',
        component: () => import(/* webpackChunkName: "invalid-app-key" */ '@/views/InvalidCompanyName.vue'),
        // beforeEnter: authGuard
    },
    {
        path: '/Chat',
        name: 'chat',
        component: () => import(/* webpackChunkName: "chat" */ '@/views/Chat.vue'),
        // beforeEnter: authGuard
    },
    {
        path: '/Finished',
        name: 'finished-chat',
        component: () => import(/* webpackChunkName: "chat" */ '@/views/ChatFinished.vue'),
    },
    {
        path: '/Onboarding',
        name: 'onboarding',
        component: () => import(/* webpackChunkName: "onboarding" */ '@/views/Onboarding.vue'),
        beforeEnter(to, from, next) {
            const token = store.getters['authToken']
            if (token) next({name: 'chat'})
            else next()
        }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
