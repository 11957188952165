import {MessageModel} from '@/models/message'
import {AUTHORS, AUTHOR_NAMES} from '@/config/constants'

import {getStorage, removeStorage, setStorage} from '@/utils/storage.helpers'

export default {
    setFontSize($store, fontSize) {
        $store.commit('SET_FONT_SIZE', fontSize)
    },
    setTenantId($store, {tenantId}) {
        $store.commit('SET_TENANT_ID', tenantId)
    },
    setFinished($store, finished) {
        $store.commit('SET_FINISHED', finished)
        const item = getStorage()
        item.finished = finished
        setStorage(item)
    },
    async resetMessages($store) {
        await $store.commit('SET_MESSAGES', [
            new MessageModel(
                null,
                AUTHORS.SUPPORT,
                AUTHOR_NAMES.SUPPORT,
                {text: $store.state.welcomeText || ''},
                new Date().getTime()
            )
        ])
    },
    addMessage($store, message) {
        $store.commit('ADD_MESSAGE', message)
    },
    addMessages($store, messages) {
        $store.commit('ADD_MESSAGES', messages)
    },
    pushMessage($store, messages) {
        $store.commit('PUSH_MESSAGE_TO_QUEUE', messages)
    },
    popMessage($store) {
        $store.commit('POP_MESSAGE_FROM_QUEUE')
    },
    clearQueue($store) {
        $store.commit('CLEAR_MESSAGE_QUEUE')
    },
    setCustomerAppOrigin($store, origin) {
        $store.commit('SET_CUSTOMER_APP_ORIGIN', origin)
    },
    async setCustomerAppKey($store, key) {
        await $store.commit('SET_CUSTOMER_APP_KEY', key)

        return key
    },
    async setCompanyName($store, name) {
        await $store.commit('SET_COMPANY_NAME', name)

        return name
    },
    async setIsExternal($store, isExternal) {
        await $store.commit('SET_IS_EXTERNAL', isExternal)

        return isExternal
    },
    async setToken($store, authToken) {
        await $store.commit('SET_AUTH_TOKEN', authToken)
        const item = getStorage()
        item.authToken = authToken
        setStorage(item)
    },
    async setIsAnOperatorOnline($store, isAnOperatorOnline) {
        await $store.commit('SET_IS_AN_OPERATOR_ONLINE', isAnOperatorOnline)
    },
    removeToken($store) {
        $store.commit('SET_AUTH_TOKEN', '')
        const item = getStorage()
        item.authToken = ''
        setStorage(item)
    },
    async removeWebChat($store) {
        await $store.commit('SET_AUTH_TOKEN', '')
        await $store.commit('SET_FINISHED', '')
        await $store.commit('SET_IS_AN_OPERATOR_ONLINE', false)
        removeStorage()
    },
    async setWelcomeText($store, welcomeText) {
        await $store.commit('SET_WELCOME_TEXT', welcomeText)
    }
}
