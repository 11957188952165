const blue = Object.freeze({
    base: '#2196F3',
    lighten5: '#EEFAFF',
    lighten4: '',
    lighten3: '',
    lighten2: '',
    lighten1: '#26A9E1',
    darken1: '',
    darken2: '',
    darken3: '',
    darken4: '#0071B6'
})
const red = Object.freeze({
    base: '#E80729',
    lighten5: '#FFF9F9',
    lighten4: '#FFEFEF',
    lighten3: '',
    lighten2: '',
    lighten1: '',
    darken1: '',
    darken2: '',
    darken3: '',
    darken4: '#7C5C60'
})
const green = Object.freeze({
    base: '#29AC64',
    lighten5: '#EFFFF1',
    lighten4: '',
    lighten3: '',
    lighten2: '',
    lighten1: ''
})
const orange = Object.freeze({
    // base: '#FF6B00',
    base: '#F5C275',
    lighten5: '#FFF3E7',
    lighten4: '',
    lighten3: '',
    lighten2: '',
    lighten1: ''
})
const grey = Object.freeze({
    base: '#3e4749',
    light: '#070707',
    light2: '#F4F8F9',
    lighten5: '#FFF3E7',
    lighten4: '#FAFBFD',
    lighten3: '#F0F5F9',
    lighten2: '#ECEFF3',
    lighten1: '#B8CAD4',
    darken1: '#B3B9BA',
    darken2: '#9AA4B3',
    darken3: '#60656D',
    
})
const shades = Object.freeze({
    black: '#000000',
    white: '#ffffff',
    transparent: 'transparent'
});
export default Object.freeze({
    blue,
    red,
    green,
    orange,
    grey,
    shades
})
